












































































































































import {Component, Vue} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import {PurchaseRecord} from '~/components/data-class/data-class'
import {DseResourceGroup, ResourceType} from '~/components/models/dse-resource-models'
import {isEmptyString} from '~/utils/misc'
import {AdminPermission} from '~/utils/permissions'

@Component({
    components: {},
    metaInfo() {
        return {
            title: 'Resource List'
        }
    }
})

export default class ResourceList extends Vue {
    static permission = AdminPermission.DSERecources

    newDialogVisible: boolean = false
    newTitle: string = ''
    newMember: string = ''
    newSubject: string = ''


    memberId = ''
    hidden: boolean = true
    type: ResourceType | '' = ''
    subject: string = ''
    sort: string = 'created'

    currentPage: number = 1
    totalCount: number = 0
    step: number = 10

    resources: PurchaseRecord[] = []
    async toPage(n) {
        this.currentPage = n
        await this.getResources()
    }
    async getResources() {
        const q: { [key: string]: any } = {
            skip: (this.currentPage - 1) * this.step,
            number: this.step,
            hidden: this.hidden,
        }

        if (this.type !== '') {
            q['type'] = this.type
        }
        if (this.subject !== '') {
            q['subject'] = this.subject
        }
        if (this.sort !== '') {
            q['sort'] = this.sort
        }
        if (this.memberId !== '') {
            q['member_id'] = this.memberId
        }

        const res = await createRequest('/dse-resources', 'get', q).send()
        this.resources = res.data.resources
        this.totalCount = res.data.count
        this.$router.push({query: this.getQuery()})
    }

    queryList = [
        {local: 'memberId', query: 'author'},
        {local: 'subject', query: 'subject'},
        {local: 'type', query: 'type'},
        {local: 'sort', query: 'sort'},
        {local: 'currentPage', query: 'page'},
        {local: 'hidden', query: 'hidden'}
    ]

    getQuery() {
        const query: { [key: string]: string } = {}

        for (const item of this.queryList) {
            if (!isEmptyString(this[item.local])) {
                query[item.query] = this[item.local]
            }
        }
        return query
    }
    async created() {
        const q = this.$route.query
        for (const item of this.queryList) {
            if (!isEmptyString(q[item.query] as string)) {
                this[item.local] = q[item.query]
            }
        }
        this.hidden = Boolean(this.hidden)
        this.currentPage = Number(this.currentPage)
        await this.getResources()
    }
    async createNewResource() {
        const res = await createRequest('/dse-resource-group', 'post', {}, {
            title: this.newTitle,
            member_id: this.newMember,
            subject: this.newSubject
        }).send()
        this.newDialogVisible = false
        await this.$router.push(`/dse-resource/${res.data.resource_id}`)
    }
    async hiddenChanged(resource: DseResourceGroup) {
        await createRequest(`/dse-resource-group/${resource._id}`, 'patch', {}, {hidden: resource.hidden}).send()
        this.$message({
            message: 'Success',
            type: 'success'
        })
    }

    typesToString(types: ResourceType[]) {
        return types.map((t) => {
            return ResourceType[t]
        }).join('\n')
    }
    async midOnClick(mid: string) {
        this.memberId = (this.memberId === mid) ? '' : mid
        await this.getResources()
    }
}

